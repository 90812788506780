@use 'sass:map';
@use 'styles/colors';

.mat-mdc-radio-group {
  .mat-mdc-radio-button {
    display: inline-block;

    .mdc-form-field {
      .mdc-radio {
        padding: 0;
      }

      .mdc-radio__native-control {
        width: 20px;
        height: 20px;
      }

      > label {
        padding-left: 8px;
        white-space: nowrap;
        cursor: pointer;
      }

      .mdc-radio--disabled + label {
        cursor: default;
      }
    }

    .mat-mdc-radio-touch-target,
    .mat-radio-ripple {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
    }
  }
}

@mixin apply-theme($theme) {
  $is-dark-theme: map.get($theme, is-dark);

  .mat-mdc-radio-group {
    .mat-mdc-radio-button {
      --mdc-radio-unselected-icon-color: #{if($is-dark-theme, #ffffff8a, #0000008a)};
    }
  }
}
