@use 'sass:map';
@use '@angular/material' as mat;

.mat-mdc-button-base.mdc-button {
  padding-left: 16px;
  padding-right: 16px;
}

.mat-mdc-button-base.mat-mdc-button:has(.material-icons, mat-icon, [matButtonIcon]) {
  padding: 0 16px;
}

@mixin apply-theme($theme) {
  $background: map.get($theme, background);
  $unthemed-color: mat.get-color-from-palette($background, raised-button);

  .mat-mdc-raised-button.mat-unthemed {
    --mdc-protected-button-container-color: #{$unthemed-color};
  }
}
